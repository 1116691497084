$primary: #FFC745;
$primary-dark: #E8B028;
$secondary: #D9DEF2;
$secondary-dark: #B1B1B1;

$color-positive: #00A524;
$color-negative: #CD0000;
$color-negative-dark: #B80000;

$background: #24222C;
$accent-color: #312F3B;
$gray: $secondary;
$gray-dark: $secondary-dark;

$text-color: $gray;

$white: #FFFFFF;
$black: #000000;
