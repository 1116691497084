body {
  margin: 0;
  background-color: $background;
}

.layout {

  &__content {
    padding-top: rem(20);
    margin-bottom: rem(50);

    @include md-max {
      position: relative;
      height: 100%;
    }

    @include lg-min {
      margin-left: $navigation_width;
      padding: rem(40);
    }
  }
}

.react-datepicker__tab-loop {
  z-index: 2;
}
