.transaction-overview {

  &__button {
    background-color: $background;
    border: none;

    svg {
      fill: $text-color;
    }
  }

  .rdt_Table {
    background-color: $background;

    .rdt_TableHeadRow,
    .rdt_TableRow,
    .fwKvpK {
      background-color: $background;
      color: $text-color;
      border-color: $gray;
    }
  }
}
