.modal {
  display: flex;
  flex-direction: column;
  background: $background;
  border-radius: rem(15);
  padding: rem(20);
  width: 90%;
  height: 80%;
  top: 10%;
  left: 5%;
  color: $text-color;

  @include lg-min {
    width: 50%;
    height: 50%;
    left: 25%;
    top: 25%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--close {
      cursor: pointer;

      svg {
        fill: $text-color;
      }
    }
  }

  &-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: calc(100% + rem(60));
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
  }
}

.update-modal,
.delete-modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &--input {
      display: flex;
      flex-direction: column;
    }

    &--buttons {
      button {
        &:first-of-type {
          margin-right: rem(16);
        }
      }
    }
  }
}
