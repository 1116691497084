.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: $background;
  width: 100vw;
  bottom: 0;
  padding: rem(10);
  z-index: 1;

  @include md-max {
    border-top: 2px solid $accent-color;
  }


  @include lg-min {
    border-right: 2px solid $accent-color;
    flex-direction: column;
    width: $navigation_width;
    height: 100vh;
    padding-top: rem(20);
  }

  &-item {

    &:not(:first-of-type) {
      @include md-max {
        margin-left: rem(16);
      }
    }

    @include lg-min {
      margin-bottom: rem(16);
    }

    svg {
      width: rem(25);
      height: auto;
      fill: $secondary;
    }

    .active {

      svg {
        fill: $primary;
      }
    }
  }

  &__profile {
    width: rem(50);
    height: rem(50);
  }

  &__pages {

    @include md-max {
      display: flex;
    }
  }
}
