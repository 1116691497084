h1, h2, h3, p, label {
  color: $text-color;
}

h1, h2, h3 {
  font-weight: bold;
}

h1 {
  font-size: rem(32);
}

h2 {
  font-size: rem(24);
}

h3 {
  font-size: rem(20);
}

p, a {
  font-size: rem(16);
}

.page-title {
  margin-bottom: rem(16);
}
