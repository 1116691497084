.import-transactions-widget {

  .import-options {
    .col-12 {
      margin-bottom: rem(16);
    }

    label {
      width: rem(150);
    }
  }

  .data-overview {
    background-color: $background;

    table {
      width: 100%;
      table-layout: fixed;

      tbody {

        tr {
          border-top: 1px solid $secondary;

          svg {
            fill: $secondary;
            cursor: pointer;
          }

          p, select {
            margin: 0;
          }

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.expense {
              color: $color-negative;
            }

            &.income {
              color: $color-positive;
            }
          }
        }
      }

      th, td {
        max-width: 20%;
        padding: rem(16);

        &:first-of-type,
        &:nth-child(4) {
          width: 15%;
        }

        &:last-of-type {
          width: 10%;
        }
      }
    }
  }
}
