.data-overview {
  background-color: $background;
  padding: rem(20);
  margin-bottom: rem(20);
  border-radius: rem(15);

  &__title {
    font-weight: bold;
    margin: 0;
  }

  &__data {
    margin: 0;

    &.profit {
      color: $color-positive;
    }

    &.loss {
      color: $color-negative;
    }
  }

  .row {

    &:first-of-type {
      padding-bottom: rem(20);
      border-bottom: 1px solid $secondary;
    }

    &:last-of-type {
      padding-top: rem(20);
    }

    .col-6 {

      &:last-of-type {
        border-left: 1px solid $secondary;
      }
    }
  }
}
