.profile {

  &__delete {
    margin-top: rem(20);

    label {
      margin-right: rem(16);
    }
  }
}
