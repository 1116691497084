.button, .btn {
  border-radius: rem(8);
  padding: rem(8) rem(16);

  &-primary {
    background-color: $primary;
    border-color: $primary;
    color: $accent-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-dark;
      border-color: $primary-dark;
    }
  }

  &-secondary {
    background-color: $secondary;
    border-color: $secondary;
    color: $accent-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $secondary-dark;
      border-color: $secondary-dark;
      color: $white;
    }
  }

  &-warning {
    background-color: $color-negative;
    border-color: $color-negative;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-negative-dark;
      border-color: $color-negative-dark;
      color: $white;
    }
  }
}
