.notification {
  position: absolute;
  top: rem(20);
  right: rem(50);
  background-color: $accent-color;
  border-radius: rem(15);
  padding: rem(20);
  display: flex;

  &__message {
    margin: 0;
  }

  svg {
    margin-right: rem(16);
  }
}
