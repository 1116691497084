select, input {
  margin-bottom: rem(16);

  &:not([type='file']) {
    border: 1px solid;
    border-radius: rem(8);
  }

  @include lg-min {
    height: 100%;
  }
}

input {

  &:not([type="submit"]) {
    padding: rem(8);
  }

  &[type='file'] {
    color: $text-color;
    padding: 0;
  }
}
